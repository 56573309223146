<template>
    <div class="tab-content" >
		<div class='card'>
			<div class='chart-header-row'>
				<span class="statement-title p-text-bold">Comparison Chart</span>
				
				<div class='buttons-container'>
					<SelectButton :class="{'period-toggle': true, 'disabled': periodTypesDisabled}" v-model="selectedPeriodType" :options="periodTypes" optionLabel="name"
						:disabled="periodTypesDisabled"/>
					<span class="p-input-icon-right" style="margin-right:10px;">
						<AutoComplete ref='metricSearchBar' id='metricSearchBar' placeholder="Select Metric" forceSelection v-model="selectedMetric" 
							:suggestions="filteredMetrics" @complete="searchMetrics($event)" field="name" @click="clearInput($event)"
							@item-select="onMetricSelected($event)"
							style="border-radius:10px;">
							<template #item="slotProps">
				
									<span v-tooltip.left="getMetricDesc(slotProps.item)">{{slotProps.item.name}}</span>
									
								</template>
						</AutoComplete>
						<i class="pi pi-search" />

					</span>
					<MoreOptionsMenu class='more-options' @click="toggleMenu"/>
					<Menu ref="menu" :model="chartMenuItems" :popup="true" />
					
				</div>
			</div>
			<div class="selected-metric" v-if="selectedMetricName" @click="toggleShowDescriptionModal()"><span class="p-text-bold" >{{selectedMetricName}}</span></div>
			<ComparisonChart  :selectedSymbol="selectedSymbol" key="comparisonChart"  ref="compChart" />
		</div>
		
		
		<div class="card">
			<div class='metrics-header-row'>
				<span class="statement-title p-text-bold">Companies</span>
				
				<div class='inputs-container'>
					<span class="p-input-icon-right" style="margin-left:auto; margin-right:10px;" v-tooltip.top="{value: disabledSearchText, disabled: !this.disableManualAdd}">
						<AutoComplete ref='compListSearchBar' id='compListSearchBar' placeholder="Add Companies from List" forceSelection v-model="selectedList" 
							:suggestions="filteredLists" @complete="searchLists($event)" field="name" @click="clearListInput($event)"
							@item-select="onListSelected($event)"
							style="border-radius:10px;" :disabled="disableManualAdd" />
								
						<i class="pi pi-search" />

					</span>
					<span class="p-input-icon-right" v-tooltip.top="{value: disabledSearchText, disabled: !this.disableManualAdd}">
							<AutoComplete placeholder="Search for Company to add" ref="compSecSearch" id="compSecSearch" forceSelection v-model="selectedSecurity" 
								:suggestions="filteredSecurities" @complete="searchSecurities($event)" field="name" 
								@item-select="onSecuritySelected($event)"
								style="border-radius:10px;" @click="onAddSecInputBarClick" :disabled="disableManualAdd">
								<template #item="slotProps">
				
									<span class='symbol'>{{slotProps.item.symbol.replace("/", ".")}}</span><br>
									<small>
										{{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
										{{ (slotProps.item.exchangeSymbol ? " - "+getExchange(slotProps.item.exchangeSymbol): "")}}
									</small>
								</template>
							</AutoComplete>
							<i class="pi pi-search" />

					</span>
				</div>
				<Button class="p-button-raised p-button-rounded" @click="onSaveToList">
          <span>Save Companies As List</span>
        </Button>
			</div>
			<div v-if="selectedSymbol.endsWith(':AQL')" style="padding-left: 16px;margin-bottom: 16px;"><small >(Note: All peers and metrics are based on the US company.)</small></div>
			
			<DataTable :value="rows"  class="p-datatable-sm data-point-table" id='compDataTable' 
				:scrollable="true" scrollHeight="calc(100vh - 310px)" scrollDirection="horizontal" v-model:selection="selectedToGraph" :selectionMode="currentSelectionMode" dataKey="securityId" 
				:metaKeySelection="false"  ref="table"
				@row-select="onRowSelected" @row-unselect="onRowUnSelected"
				>
			
				<LoadingSpinner :loading='loadingData' v-if='loadingData'/>

				<template v-else>
					<Column  header="Actions">
						<template #body="slotProps">
							<div @click="goToOverview(slotProps.data)"  class="p-button p-component p-button-icon-only view-item-button" v-tooltip.top="'Go to company overview'">
								<span class="pi pi-chart-line p-button-icon"></span><span class="p-button-label">&nbsp;</span>
							</div>
							<Button icon="pi pi-trash" @click="removePeer(slotProps.data)" class="delete-note-button" v-if="notSelectedEquity(slotProps.data)" v-tooltip.top="'Delete company from list'" />
						</template>
					</Column>
					<Column field="symbol" header="Symbol"></Column>
					<Column field="company" header="Name" style="min-width: 200px !important;"></Column>
					<Column header="% Change MTD">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Percent Price Change MTD'])}}
						</template>
					</Column>
					<Column  header="% Change YTD">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Percent Price Change YTD'])}}
						</template>
					</Column>
					<Column  header="Market Cap">
						<template #body="slotProps">
							{{formatMarketCapValue(slotProps.data.lastTradingRecord.marketCap)}}
						</template>
					</Column>
					<Column  header="P/E(LTM)">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['PE Ratio'])}}
						</template>
					</Column>
					<Column  header="P/B(LTM)">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Price To Book'])}}
						</template>
					</Column>
					<!-- <Column  header="P/CF">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Price To Cash Flow'])}}
						</template>
					</Column> -->
					<Column  header="ROA">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Return on Assets'])}}
						</template>
					</Column>
					<Column  header="RCE">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Return On Common Equity'])}}
						</template>
					</Column>
					<Column header="1Y Growth Rate">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['1 Year Annual Revenue Growth Rate'])}}
						</template>
					</Column>
					<Column  header="EV">
						<template #body="slotProps">
							{{formatValue(slotProps.data.comparisonMeasureSnapshot['Total Enterprise Value'])}}
						</template>
					</Column>
				</template>
				
				<template #empty v-if='!loadingData'>
					<div style="text-align: center;">Unable to automatically find comparable companies.  Switch to manual mode to add your own list.</div>
				</template>
			</DataTable>

		</div>
		<AttributionFooter :authors="['quoteMedia', 'XIgnite']" />

		<!-- <CreateListModal headerText='New List' :assetItems="rows" ref='createListModal' @onSave="onSave"/> -->
    </div>

	<AddToListModal :items='rows' ref='addToListModal' @addResultsToList="onSave"/>
	<UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="upgradeText" @cancel-upgrade="cancelUpgrade" />
	<Dialog id="compareItemDescriptionDialog" v-model:visible="showDescription"  :header="selectedMetricName"  :position="'top'" style="width:40rem;">
    
    
        <div class="desc-content" >
			{{selectedMetricDescription}}
        </div>
        <template #footer>
            </template>
    
    </Dialog>
</template>

<script>
import { mapState } from 'vuex';
//import format from 'date-fns/format';
import numeral from 'numeral';
//import PillMenuBar from '../menu/PillMenuBar';
import LoadingSpinner from '../common/LoadingSpinner';
import AttributionFooter from '../footer/AttributionFooter.vue';
import ComparisonChart from '../charts/highcharts/ComparisonChart.vue';
import AddToListModal from '../modal/assetLists/AddToListModal.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DataService from '../../service/DataService';
import ResearchService from '../../service/ResearchService';
import SelectButton from 'primevue/selectbutton';
import MoreOptionsMenu from '../menu/MoreOptionsMenu';
import AutoComplete from 'primevue/autocomplete';
//import SearchDialog from '../equity/SearchDialog.vue';
import SecurityFormatter from '../../common/SecurityFormatter';
import {PREMIUM_SUBSCRIPTION_TYPES, BASIC_METRICS, PE_RATIO} from '../../common/constants';
import UserUtils from '../../utilities/UserUtils';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal.vue';
import Dialog from 'primevue/dialog';

const ANNUAL = 1;
const QUARTERLY = 2;
// const DAILY = 4;
const VALUE_TYPE_FORMATTER = {
	'Millions': '0.0a',
	'Ratio': '0.00',
	'Percent': '0.00%',
	'$ & cents': '0.00a',
	'Actual': '0.00',
	'Decimal Percentage' : '0.00%',
}

const METRICS = BASIC_METRICS;

export default {
    name: "Compare",
    data() {
		return {
			PREMIUM_SUBSCRIPTION_TYPES,
			justMounted: false,
			active: false,
			columns: null,
			rows:[],
			disableManualAdd: true,
			currentSymbol: "",

			disabledSearchText: 'The maximum amount of companies you can compare is 10. Delete companies to add more or use the Lists feature in Sandbox to build unlimited company lists.',
			
			selectedToGraph: [],
			loadingData: false,
			
			currentSelectionMode: "multiple",
			periodTypes: [{name:'Annual', value: ANNUAL}, {name:'Quarterly', value: QUARTERLY}],
			selectedPeriodType: null,
			scrollDirection: null,
			scrollable: false,
			selectedMetric: null,//{name: 'PE Ratio', measureId:319, valueType: 'Ratio', periodTypes: [DAILY]},
			filteredMetrics: [],
			chartMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.$refs.compChart.openSnapshotDialog();
                    }
                },
               
			],
			periodTypesDisabled: true,
			selectedSecurity: {name:""},
			filteredSecurities: [],


			selectedList: null,
			filteredLists: [],
			selectedMetricName: null,
			selectedMetricDescription: null,
			showDescription: false,
			upgradeText: 'To get full access to hundreds of metrics and the other premium features below, upgrade to premium.',
		}
	},
    components : {
        //PillMenuBar, 
		DataTable, Column,  SelectButton, MoreOptionsMenu, ComparisonChart,AutoComplete,
		//SearchDialog,
		AttributionFooter, AddToListModal, LoadingSpinner,
		UpgradeToPremiumModal,
		Dialog,
    },
    computed: {
        ...mapState(['selectedEquity']),
		...mapState(['savedCompData']),
		...mapState(['compareMetric']),

        selectedSymbol() {
            return SecurityFormatter.getSymbolViaCdnExchanges(this.$store.state.selectedEquity);
        },

		saveToListButtonDisabled() {
			return !(this.selectedSaveToList && this.selectingList);
		},
		isPremUser() {
			return UserUtils.isPremiumSubscriber();
		},

    },
    watch:{
		selectedPeriodType() {
			if (this.active) {
				this.savedCompData.selectedPeriodType = this.selectedPeriodType;
				this.$store.commit("SET_SAVED_COMP_DATA", this.savedCompData);

			
				console.debug("period type changed" );
				this.updatePeriods();
			}

		},
		selectedMetric() {
			if( this.selectedMetric == null ){
				this.periodTypesDisabled = true;
			}
		},
		rows: { // this is how you watch an array
			handler(newVal, oldVal) {//eslint-disable-line
				if( newVal.length < 10){
					this.disableManualAdd = false;
				}
				else {
					
					this.disableManualAdd = true;
				}
			},
			deep: true
		},
	},

    mounted() {
		this.justMounted = true;
		console.debug(this.$options.name + " mounted");
	},
	activated() {
		this.active = true;
		console.debug(this.$options.name + " activated");
		this.selectedList = null;
		this.selectedSaveToList = null;
		if (this.hasSavedComparableComps() && this.savedCompData.selectedSymbol === this.selectedSymbol) {
				this.rows = this.savedCompData.comparableComps;

				this.rows.forEach((r) => {
					r['metricData'] = new Map();
				});

				this.selectedToGraph = this.rows.filter((r) => r.selected);
				this.$refs.compChart.updateSecurities(this.selectedToGraph, false);

			if (this.savedCompData.selectedCompMetric) {
				this.selectedMetric = this.savedCompData.selectedCompMetric;
				this.onMetricSelected({value: this.savedCompData.selectedCompMetric}, false);
			}

			if (this.savedCompData.selectedPeriodType) {
				this.selectedPeriodType = this.savedCompData.selectedPeriodType;
				this.updatePeriods(false);
			}

		} else {
				this.selectedMetric = (this.compareMetric ? this.compareMetric : PE_RATIO);
				this.onMetricSelected({value: this.selectedMetric});
				this.$store.commit("SET_SAVED_COMP_DATA", {selectedCompMetric: this.selectedMetric, comparableComps: null, selectedSymbol: this.selectedSymbol, selectedPeriodType: null});

				this.currentSymbol = this.selectedSymbol;
				this.resetTable();
				this.$nextTick().then(() => {
					if (this.active) {
						this.fetchData();
					}
				});
				
		}
		setTimeout(() => {window.dispatchEvent(new Event('resize'))}, 2000); 
		
		
		
		this.justMounted = false;
	},
	deactivated() {
		this.justMounted = false;
		this.active = false;
        console.debug(this.$options.name + " deactivated");

		if (!this.$route.fullPath.includes('equity')) {
			this.$store.commit("SET_SAVED_COMP_DATA", null);
		}
	},
    methods: {

		getMetricDesc(m){
			if( m ){
				if(m.description){
					if(m.description.length > 500){
						return m.description.substring(0,500)+"...";
					}
					else {
						return m.description;
					}
				}
				else {
					return ""
				}
			}
			else {
				return "";
			}
			
		},

		toggleShowDescriptionModal() {
			this.showDescription = !this.showDescription
			

		},
		showUpgradeModal() {
			this.$refs.upgradeToPremiumModal.open();
		},
		cancelUpgrade() {

		},

		openCreateListModal() {
			this.$refs.createListModal.open();
		},
		onSave(value) {
			this.selectedSaveToList = value;

			this.saveToList();
		},

		onShown() {
			this.selectingList = false;
			this.selectedSaveToList = null;
			this.filteredSaveToLists = [];
		},

		goToOverview(item) {
			this.$store.dispatch({
				type: 'setSelectedEquity',
				selectedEquity: item,
			});

			this.$router.push({ path: '/equity' });
		},

		// getViewCompanyLink(item) {
		// 	console.log(item);
		// 	return '/index.html#/equity?sym='+item.symbol+'&assetType='+item.assetType+"&exSym="+item.exchangeSymbol;
		// },

		viewCompany(item) {
			
			ResearchService.setSelectedEquity(item.exchangeSymbol, item.symbol, () => {
	
				this.$router.push({ path: '/equity' });
			});
			
		},

		saveToList() {
            if( !this.selectedSaveToList.name || this.selectedSaveToList.name === undefined) {
                
                console.log("saving to new list called " + this.selectedSaveToList);
                ResearchService.saveCompareListToNewList(this.selectedSaveToList, this.rows).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Comparable saved to list " +this.selectedSaveToList, detail: '', life: 3000, group: 'tc' });
						this.$refs.addToListModal.close();
                    }
                    else {
                        console.log("error saving results to new list "+ resp.data.message);
                        this.$toast.add({ severity: 'error', summary: "Error saving results to list." +this.selectedSaveToList, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();
                    }
                   
                })
                .catch(error => {
                    console.log("error saving results to new list "+ error);
                    this.$toast.add({ severity: 'error', summary: "Error saving results to list." +this.selectedSaveToList, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                    this.$refs.addToListModal.close();
                    
                });
            }
            else {
                console.log("saving to existing list called " + this.selectedSaveToList.name);
                ResearchService.saveCompareListToList(this.selectedSaveToList.assetListId, this.rows).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: "Comparable companies saved to list " +this.selectedSaveToList.name, detail: '', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();

                    }
                    else {
                        console.log("error saving results to list "+ resp.data.message);
                        this.$toast.add({ severity: 'error', summary: "Erro saving results to list." +this.selectedSaveToList.name, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                        this.$refs.addToListModal.close();
                    }
                   
                })
                .catch(error => {
                    console.log("error saving results to list "+ error);
                    this.$toast.add({ severity: 'error', summary: "Erro saving results to list." +this.selectedSaveToList.name, detail: 'Please try again, and let us know if the problem persists.', life: 3000, group: 'tc' });
                    this.$refs.addToListModal.close();
                    
                });
            }
        },

		searchLists(event) {
			ResearchService.getAssetLists(10, 0, event.query).then((resp) => {
				if( resp.data.status == "success"){
					this.filteredLists  = resp.data.lists;
					
				}
			});
		},

		clearListInput(e) {
			e['query'] = '';
            this.$refs.compListSearchBar.$emit('complete', e)
			this.$refs.compListSearchBar.show();
        },

		onListSelected(event) {
			
			this.selectedList = event.value;
			// for now just add the top 10 items.
			ResearchService.getAssetListItems(this.selectedList.assetListId, 10, 0, null).then( (resp) => {
				
				if( resp.data.status == "success"){
					//console.log(JSON.stringify(resp.data));
					resp.data.items.forEach(item => {
						if( item.assetType == 'equity' && this.rows.length < 10) {
							let alreadyExists = false;
							for( var i = 0; i < this.rows.length; ++i ) {
								if( item.securityId == this.rows[i].securityId) {
									alreadyExists = true;
									break;
								}
							}
							if( !alreadyExists) {
								item['company'] = item.name; // need this for later
								this.rows.push(item);
							}
						}
					});
					
				}
				else {
					console.error("error loading asset list items " + resp.data.message);
					
				}
				
				
			}).catch(error => {
				console.error("error loading asset list items " + error);
					
			});
		},

		onSaveToList() {
			this.$refs.addToListModal.open();
		},
		onListToAddItemSelected() {
			this.selectingList = true;
		},

		hasSavedComparableComps() {
			return this.hasSavedData() && this.savedCompData.comparableComps && this.savedCompData.comparableComps.length > 0;
		},
		hasSavedData() {
			return this.savedCompData && this.savedCompData.selectedSymbol === this.selectedSymbol;
		},

		clearInput(e) {
			e['query'] = '';
            this.$refs.metricSearchBar.$emit('complete', e)
			this.$refs.metricSearchBar.show();
			this.selectedMetric = {name:""}
        },

		notSelectedEquity(toCompare){
			
			return toCompare.securityId != this.selectedEquity.securityId;
		},

		removePeer(sec) {
			this.rows = this.rows.filter(val => val.securityId !== sec.securityId);
		
			if (this.savedCompData) {
				this.savedCompData.comparableComps = this.rows;
				this.$store.commit("SET_SAVED_COMP_DATA", this.savedCompData);
			} else {
				this.$store.commit("SET_SAVED_COMP_DATA", {selectedSymbol: this.selectedSymbol, selectedCompMetric: null, comparableComps: this.rows});
			}

			let oldLength = this.selectedToGraph.length;
			this.selectedToGraph = this.selectedToGraph.filter(val => val.securityId !== sec.securityId);
			if( oldLength != this.selectedToGraph.length){
				this.$refs.compChart.updateSecurities(this.selectedToGraph);
			}
		},
		getExchange(exchangeSymbol) {
            if( exchangeSymbol == 'XNAS') {
				return "NASDAQ";
			}
			else if( exchangeSymbol == 'XNYS') {
				return "NYSE";
			}
			else if( exchangeSymbol == 'OOTC') {
				return "OTC";
			}
			else if( exchangeSymbol == 'XTSE') {
				return "TSX";
			}
			else if( exchangeSymbol == 'XTSX') {
				return "TSXV";
			}
			else if( exchangeSymbol == 'XCNQ') {
				return "CSE"
			}
            else if( exchangeSymbol == 'ARCX'){
                return "NYSE ARCA"
            }
			else {
				return exchangeSymbol;
			}
        },
		searchSecurities(event){
            let type = "Equities"
            ResearchService.queryMarket(type, event.query, 10, 0).then(response => {
				
                let marketQueryResults = response.data;
                
                this.filteredSecurities = marketQueryResults.securities.filter((res) => {
					let alreadySelected = false;

					this.rows.forEach((sec) => {
						if (sec.securityId === res.securityId) {
							alreadySelected = true;
						}
					})

					return alreadySelected ? false : true;
				});
          })
          .catch(error => {
              this.filteredSecurities = []
              
            console.debug('There was an error:', error.response)
          })
        },
       

		
		onSecuritySelected(event) {
			let selected = event.value;
			this.selectedSecurity = { name: `${selected.symbol} - ${selected.company}`, securityId:selected.securityId};
			//this.$toast.add({ severity: 'success', summary: 'Loading equity...', life: 2000, group: 'center' });
			this.loadingData = true;
			DataService.getComparisonMetrics(selected.securityId).then(resp => {
				if( resp.data.status == "success"){
					
					this.rows.push(resp.data.security);
					this.selectedSecurity = { name: ""};
					this.$refs.compSecSearch.$el.blur();
					document.getElementById('compSecSearch').blur();

					if (this.savedCompData) {
						this.savedCompData.comparableComps = this.rows;
						this.$store.commit("SET_SAVED_COMP_DATA", this.savedCompData);
					} else {
						this.$store.commit("SET_SAVED_COMP_DATA", {selectedSymbol: this.selectedSymbol, selectedCompMetric: null, comparableComps: this.rows});
					}
				}
				else {
					console.log("error getting metrics for manually added security", resp.data.message);
				}
				this.loadingData = false;
			});   

			
		},

		onAddSecInputBarClick() {
			this.selectedSecurity = { name: ""};
		},

		searchMetrics(event) {
			if( this.isPremUser ){
				DataService.searchMetrics(this.selectedEquity.securityId,null, event.query.toLowerCase(), 300, 0).then(resp => {
					if( resp.data.status == "success"){
						this.filteredMetrics = resp.data.results.data;
					}
					else {
						console.log("error searching metrics", resp.data);
					}
				}).catch(err => {
					console.log("error searching metrics", err);
				})
			}
			else {
				if (!event.query.trim().length) {
					this.filteredMetrics = [...METRICS];
				}
				else {
					this.filteredMetrics = METRICS.filter((metric) => {
						return metric.name.toLowerCase().startsWith(event.query.toLowerCase()) || metric.measureId == -1;
					});
				}
			}
		},
		onMetricSelected(event, buildChart = true) {
			//console.log('metric selected: ', event);
			if (event.value.measureId == -1 ) {
				this.showUpgradeModal();
			}
			else {
				if( event.value.name != '' ){
					this.selectedMetricName = event.value.name;
					
					this.selectedMetricDescription =  event.value.description;
				}
				

				if( this.isFiscalMetric(event.value) ){
					//enable period type
					this.periodTypesDisabled = false;
				}
				else {
					//disable period type
					this.periodTypesDisabled = true;
				}
				this.$refs.compChart.updateMetric(event.value, buildChart);

				if (this.savedCompData) {
					this.savedCompData.selectedCompMetric = this.selectedMetric;
					this.$store.commit("SET_SAVED_COMP_DATA", this.savedCompData);
				} else {
					this.$store.commit("SET_SAVED_COMP_DATA", {selectedSymbol: this.selectedSymbol, selectedCompMetric: this.selectedMetric, comparableComps: null});
				}
				if( this.isPremUser ){
					DataService.saveCompareMetric(this.selectedMetric.measureId).then(resp => {
						if( resp.data.status == 'success'){
							this.$store.commit("SET_COMPARE_METRIC", this.selectedMetric);
						}
						else {
							console.log("error saving compare metric", resp.data);
						}
					}).catch(err => {
						console.log("error saving compare metric", err);
					})
				}
				else {
					this.$store.commit("SET_COMPARE_METRIC", this.selectedMetric);
				}
				
				
				if( document.getElementById('metricSearchBar') ) {
					this.$refs.metricSearchBar.$el.blur();
					document.getElementById('metricSearchBar').blur();
				}
			}
		},

		addSelectedProp(equity) {
			let obj = equity;

			obj['selected'] = false;

			return obj;
		},

		toggleSelectionForEquity(equity) {
			this.savedCompData.comparableComps.forEach((comp) => {
				if (comp.securityId === equity.securityId) {
					comp.selected = !comp.selected;
				}
			})

			this.$store.commit("SET_SAVED_COMP_DATA", this.savedCompData);
		},
		
        isFiscalMetric(selectedMetric){
           
            return (selectedMetric.periodTypes && (selectedMetric.periodTypes[0] == ANNUAL || selectedMetric.periodTypes[0] == QUARTERLY
				|| selectedMetric.periodTypes[0] == 'Yearly' || selectedMetric.periodTypes[0] == 'Quaterly')
			);
        },
		onRowSelected(event){
			this.toggleSelectionForEquity(event.data)
			this.$refs.compChart.updateSecurities(this.selectedToGraph);
		},

		onRowUnSelected(event){
			this.toggleSelectionForEquity(event.data)
			this.$refs.compChart.updateSecurities(this.selectedToGraph);
		},
		formatMarketCapValue(marketCap){
			if( marketCap ){
				return numeral(marketCap).format(VALUE_TYPE_FORMATTER['Millions']);
			}
			else {
				return '-';
			}
		},
		formatValue(measure) {
			//console.log("checking "+measure.measureValue)
			if( measure && measure.measureValue) {
				if( measure.valueType == 'Decimal Percentage'){
					return numeral(measure.measureValue).divide(100.0000).format(VALUE_TYPE_FORMATTER[measure.valueType]);
				}
				else if (measure.valueType == 'Ratio') {
					return numeral(measure.measureValue).format(VALUE_TYPE_FORMATTER[measure.valueType]) + 'x';
				}
				else {
					return numeral(measure.measureValue).format(VALUE_TYPE_FORMATTER[measure.valueType]);
				}
			}
			else {
				return "-";
			}
			

			
		},
		updatePeriods(buildChart = true) {
			this.$refs.compChart.updatePeriodType(this.selectedPeriodType, buildChart);
		},
		toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        resetTable() {
			
			
			this.selectedPeriodType = this.periodTypes[0];
			this.rows = [];
			this.columns = [];
			this.selectedToGraph = [];
			this.categories = [];
			
		},

		fetchData(){
			
			this.rows = [];
			this.loadingData=true;
			
			
			DataService.getPeers(this.selectedEquity.securityId)
				.then(resp =>{
					//console.log(JSON.stringify(resp.data));
					
					if( resp.data.status == "success") {
						this.rows = resp.data.peers;

						const rows = this.rows.map(this.addSelectedProp);

						if (this.savedCompData) {
							this.savedCompData.comparableComps = rows;
							this.$store.commit("SET_SAVED_COMP_DATA", this.savedCompData);
						} else {
							this.$store.commit("SET_SAVED_COMP_DATA", {selectedSymbol: this.selectedSymbol, selectedCompMetric: null, comparableComps: rows});
						}
					}
					
					this.preSelectUptoThreeRows();
					
					
					
					this.scrollable = true;
					this.loadingData=false;
				});
				
		},

		preSelectUptoThreeRows(){
			if( this.rows.length > 0 ){
				let i = 0;
				let selected = []
				while( i < 3 && i < this.rows.length){
					this.toggleSelectionForEquity(this.rows[i]);
					selected.push(this.rows[i]);
					++i;
				}
				this.selectedToGraph = selected;
				this.$refs.compChart.updateSecurities(this.selectedToGraph);
			}
		}


    }


}
</script>

<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.selected-metric{
	text-align: center;
	cursor: pointer;
}


::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	width:21rem;
}
::v-deep(.p-inputtext:enabled:hover) {
	border-color: #33CC99;
}
::v-deep(.p-inputtext:enabled:focus) {
	border-color: #33CC99;
	box-shadow: none;
}

.card {
	padding: 16px;
	width: 100%;
	border-radius: 16px;
	margin-bottom: 16px;
}
.card:last-child {
	margin-bottom: 0px;
}
.chart-header-row {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.data-point-table {
	min-height: 100px;
}

::v-deep(.delete-note-button) {
    border: 1px solid #fa0505;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-left: 8px;
}

::v-deep(.delete-note-button:hover) {
    border-color:#fa0505;
    background-color: #fa0505;
    color: #FFFFFF;
}
::v-deep(.delete-note-button:focus) {
    box-shadow: none;
    border-color: #fa0505;
}

/* ::v-deep(.p-datatable-scrollable .p-datatable-wrapper) {
	direction: rtl;
} */
::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar) {
	/* width */
	width: 10px;

	height: 10px;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar-track) {
	background-color: #F2F2F2;
	opacity: 1;
	transition: background-color .2s;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar-thumb) {
	background-color: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 9px solid #BFBFBF;  /* creates padding around scroll thumb */
}
::v-deep(.data-point-table .p-datatable-thead > tr > th) {
	background: #ffffff;
	color: #999;
	/* padding-left: 2.75em; */
	font-weight: normal;
	min-width: 140px !important;
}
::v-deep(.data-point-table .p-datatable-thead > tr > th .p-column-title) {
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr > td) {
	/* padding-top: 12px;
	padding-bottom: 12px;
	padding-left: 38.5px; */
	min-width: 140px !important;
}
::v-deep(.data-point-table .p-datatable-tbody > tr.template-row) {
	color: black;
}
::v-deep(.data-point-table .p-datatable-tbody > tr.template-row > td) {
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 550;
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr > td) {
	padding-top: 5px;
	padding-bottom: 5px;
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr:not(.p-highlight).industry-template-row:hover),
::v-deep(.data-point-table .p-datatable-tbody > tr:not(.p-highlight):hover),
::v-deep(.data-point-table .p-datatable-tbody > tr.p-highlight ){
	background: #32364E;
	color:#ffffff;
}

::v-deep(.negative) {
	color: #E63E3E;
}

.metrics-header-row {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
} 

.metrics-header-row .inputs-container{
	margin-top: 2px;
}

.inputs-container,
.buttons-container {
	display: flex;
	align-content: center;
	margin-left: auto;
	margin-right: 8px;
}

.statement-title {
	font-size: 26px;
	color: #32364e;
}

 ::v-deep(.custom .p-button) {
        font-family: "Trebuchet MS", "Verdana";
 }

::v-deep(.period-toggle) {
	margin-right: 8px;
	padding-top:4px;
	padding-bottom:4px;
}
 ::v-deep(.period-toggle.disabled) {
	opacity: 0.4;
 }
 ::v-deep(.period-toggle .p-button.active),
    ::v-deep(.period-toggle .p-button:enabled:hover) {
        background: #32364E;
        color: #FFFFFF;
        
    }
	

	::v-deep(.period-toggle .p-button:focus) {
		box-shadow: none;
	}

	
	::v-deep(.period-toggle.p-selectbutton .p-button.p-highlight) {
		background: #32364E;
        color: #FFFFFF; 
		pointer-events: none;
		border-radius: 2rem;
		z-index: 2;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button ) {
		border-color: #32364E;
		padding-left: 20px;
		padding-right: 20px;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:first-of-type ) {
		left: 20px;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
		border-radius: 2rem 0 0 2rem;
	}
		::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
		border-radius: 0 2rem 2rem 0;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #32364E;
        color: #FFFFFF; 
	}
	::v-deep(.period-toggle.disabled.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #FFFFFF;
        color: #32364E; 
		border-color: #32364E;
		cursor: default;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button-label) {
		padding: 0px;
	}

	::v-deep(.view-item-button) {
    border: 1px solid #32364e;
    background-color: #FFFFFF;
    color: #32364e;
    padding: 0px;
    height: 31px;
    border-radius: 30px;
    /* flex: 0 1 47px; */
	box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

::v-deep(.view-item-button:hover) {
    border-color:#32364e;
    background-color: #33cc99;
    color: #FFFFFF;
}
::v-deep(.view-item-button:focus) {
    box-shadow: none;
    border-color: #33cc99;
}

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
    padding: 5px 0px 5px 0px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td:first-child) {
    padding: 5px 0px 5px 16px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td:last-child) {
    padding: 5px 16px 5px 0px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td .note-title) {
    color: #32364e;
    font-size: 16px;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td .note-posted-date) {
    color: #bfbfbf;
}
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
    color: #32364e;
}
::v-deep(.p-paginator-first:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-prev:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-next:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator-last:not(.p-disabled):not(.p-highlight):hover),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover) {
    border-color: #32364e;
    background: #32364e;
    color: #FFFFFF;
}
::v-deep(.p-paginator-first:focus),
::v-deep(.p-paginator-prev:focus),
::v-deep(.p-paginator-next:focus),
::v-deep(.p-paginator-last:focus),
::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:focus) {
    box-shadow: none;
}

</style>
