<template>
    <Dialog header="Add to List" v-model:visible="displayAddToListModal" :modal="true" :closable="true" class='add-to-list-modal' @show='onShown'>
        <div class='top-bar'>
            <span class="p-input-icon-left save-to-list-container">
                <AutoComplete ref='saveToListSearchBar' id='saveToListSearchBar' placeholder="Select List" forceSelection  v-model="selectedSaveToList" 
                    :suggestions="filteredSaveToLists" @complete="searchSaveToLists($event)" field="name" @item-select='onListToAddItemSelected' @click="clearSaveToListInput($event)"
                    @input='selectingList = false'/>
                <i class="pi pi-search" />
                <!-- <Button label="New" class='new-list-button p-button-raised p-button-rounded' @click='createNewList=true'/> -->
            </span>
            <Button label='Create New List' class='action p-button-raised p-button-rounded' @click='openCreateListModal' />
        </div>
        <!-- <InputText type="text" v-model="newListName"  placeholder="Enter name for list" maxlength="50" v-show="createNewList"/> -->
        <template #footer>
            <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
            <Button label="Save" class='action p-button-raised p-button-rounded' :disabled="saveToListButtonDisabled" @click='saveToExistingList'/>
        </template>
    </Dialog>
	<CreateListModal headerText='New List' :assetItems="items" ref='createListModal' @onSave="saveToNewList"/>
</template>

<script>
import CreateListModal from '../../modal/assetLists/CreateListModal.vue';

import Dialog from 'primevue/dialog';
import AutoComplete from 'primevue/autocomplete';

import ResearchService from '../../../service/ResearchService';

export default {
    name: "AddToListModal",
    emits: ['addResultsToList'],
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    components: {
        CreateListModal, Dialog, AutoComplete
    },

    data() {
        return {
            displayAddToListModal: false,
			filteredSaveToLists: [],
			selectedSaveToList: null,
			selectingList: false
        }
    },
    computed: {
        saveToListButtonDisabled() {
			return !(this.selectedSaveToList && this.selectingList);
		}
    },

    methods: {
        open() {
            this.displayAddToListModal = true;
        },
        close() {
            this.displayAddToListModal = false;
        },
        openCreateListModal() {
			this.$refs.createListModal.open();
		},
		saveToNewList(value) {
			this.$refs.createListModal.close();
			this.$emit('addResultsToList', value);
		},
        saveToExistingList() {
            this.$emit('addResultsToList', this.selectedSaveToList);
        },
		onShown() {
			this.selectingList = false;
			this.selectedSaveToList = null;
			this.filteredSaveToLists = [];
		},
        onListToAddItemSelected() {
			this.selectingList = true;
		},
        clearSaveToListInput(e) {
			e['query'] = '';
			this.selectedSaveToList = null;
			this.$refs.saveToListSearchBar.$emit('complete', e)
            this.$refs.saveToListSearchBar.show();
		},

        searchSaveToLists(event) {
            ResearchService.getAssetLists(10, 0, event.query).then((resp) => {
                if( resp.data.status == "success"){
                    this.filteredSaveToLists  = resp.data.lists;
                }
            });
        },
    }

}
</script>

<style>
.add-to-list-modal {
	font-family: "Trebuchet MS", "Verdana";
	box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
	width: 480px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.add-to-list-modal .p-dialog-header {
	padding: 16px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.add-to-list-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
	font-size: 24px;
}

.add-to-list-modal .p-dialog-content {
	border-top: 1px solid #BFBFBF;
	border-bottom: 1px solid #BFBFBF;
	padding: 16px;
}

.add-to-list-modal .p-dialog-footer {
	display: flex;
	align-items: center;
	padding: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}
</style>
<style scoped>
.add-to-list-modal *:not(.pi, [class='material-icons']) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(#saveToListSearchBar .p-autocomplete-input) {
    font-family: "Trebuchet MS", "Verdana";
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 8px 8px 32px;
    flex: 1;
    color: #32364e;
    font-size: 16px;
	width: 280px;
}
::v-deep(#saveToListSearchBar:enabled:hover) {
	border-color: #33CC99;
}
::v-deep(#saveToListSearchBar:enabled:focus) {
	border-color: #33CC99;
	box-shadow: none;
}

.add-to-list-modal .p-button {
	background: #FFFFFF;
	border-radius: 50px;
	padding: 8px 16px;
	color: #32364E;
	border: 2px solid;
	width: 100%;
 }

 
.add-to-list-modal .action {
	border-color: #33cc99;
 }

 .add-to-list-modal .action:hover {
	color: #FFFFFF;
	background: #33cc99;
	border: 2px solid #33cc99;
 }

 .add-to-list-modal .p-dialog-content .top-bar {
	display: flex;
	align-items: center;
 }
 .add-to-list-modal .p-dialog-content .save-to-list-container {
     margin-right: 16px;
 }

 .add-to-list-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 8px;
 }

 .add-to-list-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 
</style>
