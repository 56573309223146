<template>
    <Dialog :header="headerText" v-model:visible="display" :modal="true" :closable="true" class='create-list-modal' @show='setName'>
        <InputText class='create-new-list-input' type="text" v-model="name"  placeholder="Enter name for list" maxlength="50"/>
        <template #footer>
            <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
            <Button label="Save" class='accept p-button-raised p-button-rounded' @click='save' :disabled="saveButtonDisabled"/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

export default {
    name: 'CreateListModal',
    emits: ['onSave'],
    data() {
        return {
            display: false,
            name: ''
        }
    },
    components: {
        Dialog, InputText
    },
    props: {
        headerText: {
            type: String,
            required: true
        },
        listName: {
            type: String,
            default: ''
        }
        
    },

	computed: {
        saveButtonDisabled() {
			return !this.name || this.name.trim() == '';
		}

    },
    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        save() {
            this.$emit('onSave', this.name);
        },
        setName() {
            this.name = this.listName;
        }
    },
}
</script>

<style>
.create-list-modal {
    font-family: "Trebuchet MS", "Verdana";
	box-shadow:  0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
	width: 300px;	
    border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.create-list-modal .p-dialog-header {
	padding: 16px;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

.create-list-modal .p-dialog-header .p-dialog-title {
	font-weight: bold;
    font-size: 24px;
}

.create-list-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 16px;
}

.create-list-modal .p-dialog-footer {
	display: flex;
	align-items: center;
	padding: 16px;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

</style>
<style scoped>
.create-list-modal *:not(.pi, [class='material-icons']) {
    font-family: "Trebuchet MS", "Verdana";
}

.create-list-modal .p-dialog-content > input{
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 40px;
    padding: 8px;
    flex: 1;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}


.create-list-modal .p-dialog-footer .p-button {
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	border-radius: 50px;
	padding: 8px 16px;
	color: #32364E;
	border: 2px solid;
	width: 100%;
 }

 .create-list-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 1rem;
 }

 .create-list-modal .p-dialog-footer .accept {
	border-color: #33cc99;
 }

 .create-list-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33cc99;
	border: 2px solid #33cc99;
 }

 .create-list-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
	border: 2px solid #32364E;
 } 
</style>