<template>
    <highcharts :options="chartOptions" :constructor-type="'stockChart'" ref="chartComponent" class="chart comparisonChart"></highcharts>
    <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="50"/>
</template>
<script>
import DataService from '../../../service/DataService';

import numeral from 'numeral';
import parse from 'date-fns/parse';
import add from 'date-fns/add';

import SnapshotDialog from '../../modal/SnapshotDialog.vue';

const ANNUAL = 1;
const QUARTERLY = 2;
const DAILY = 4;

export default {
    name: 'ComparisonChart',
    props: {
        selectedSymbol: {
            type: String,
            required: true,
        },
    },
    components: {
        SnapshotDialog,
    },

    data() {
        return {
            selectedMetric: { name: 'PE Ratio', measureId: 319, valueType: 'Ratio', periodTypes: [DAILY] },
            securities: [],
            selectedPeriodType: { name: 'Annual', value: ANNUAL },
            justMounted: false,

            snapshotTarget: null,

            displaySnapshot: false,

            currentSymbol: '',
            annualCategories: ['FY-5', 'FY-4', 'FY-3', 'FY-2', 'FY-1', 'FY Current'],
            quarterlyCategories: ['FQ-20', 'FQ-19', 'FQ-18', 'FQ-17', 'FQ-16', 'FQ-15', 'FQ-14', 'FQ-13', 'FQ-12', 'FQ-11', 'FQ-10', 'FQ-9', 'FQ-8', 'FQ-7', 'FQ-6', 'FQ-5', 'FQ-4', 'FQ-3', 'FQ-2', 'FQ-1', 'FQ Current'],

            chartOptions: {
                //xAxis:{ type: 'category',  categories: this.fiscalCategories},
                chart: {
                    height: 350
                },
                legend: {
                    enabled: true,
                    verticalAlign: 'top',
                },

                navigator: {
                    enabled: false,
                },
                scrollbar: {
                    enabled: false,
                },
                rangeSelector: {
                    enabled: false,
                },
                stockTools: {
                    gui: {
                        buttons: ['simpleShapes', 'separator', 'lines', 'separator', 'toggleAnnotations', 'separator', 'fullScreen'],
                    },
                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        opacity: 0.85,
                    },
                },
                //series: [],
                responsive: {
                    rules: [
                        {
                            condition: {
                                maxWidth: 800,
                            },
                            chartOptions: {
                                rangeSelector: {
                                    inputEnabled: false,
                                },
                            },
                        },
                    ],
                },
                time: {
                    useUTC: false,
                },
            },
        };
    },
    mounted() {
        console.debug('comparison chart mounted');

        this.currentSymbol = this.selectedSymbol;

        this.clearChart();
        //this.$refs.chartComponent.chart.setTitle(this.currentSymbol+" Comparison");
        this.$refs.chartComponent.chart.reflow();
        this.justMounted = true;
    },
    activated() {
        if (!this.justMounted) {
            if (this.selectedSymbol != this.currentSymbol) {
                this.clearChart();
                // this.$refs.chartComponent.chart.setTitle(this.currentSymbol+" Comparison");
                this.$refs.chartComponent.chart.reflow();
            }
        } else {
            this.justMounted = false;
        }
    },
    deactivated() {
        this.displaySnapshot = false;
    },

    computed: {
        tooltip() {
            let dateFormat = '';
            if (this.isFiscalMetric(this.selectedMetric)) {
                dateFormat = '{point.fiscalEndDate:%Y-%m-%d}';
            } else {
                dateFormat = '{point.x:%Y-%m-%d}';
            }

            if (this.isDollarBased(this.selectedMetric)) {
                return {
                    shared: true,
                    split: false,
                    headerFormat: '<table>',
                    pointFormat: '<tr><td style="color:{series.color}; padding: 0">{series.name}: </td>' + '<td style="padding:0;text-align:right;"><b>${point.y:,.2f}</b>  (' + dateFormat + ')</td></tr>',
                    footerFormat: '</table>',
                    useHTML: true,
                };
            } else if (this.selectedMetric.valueType == 'Ratio') {
                return {
                    shared: true,
                    split: false,
                    headerFormat: '<table>',
                    pointFormat: '<tr><td style="color:{series.color}; padding: 0">{series.name}: </td>' + '<td style="padding:0;text-align:right;"><b>{point.y:.1f}x</b>  (' + dateFormat + ')</td></tr>',

                    footerFormat: '</table>',
                    useHTML: true,
                };
            } else if (this.selectedMetric.valueType == 'Decimal Percentage' || this.selectedMetric.valueType == 'Percent') {
                return {
                    shared: true,
                    split: false,
                    headerFormat: '<table>',
                    pointFormat: '<tr><td style="color:{series.color}; padding: 0">{series.name}: </td>' + '<td style="padding:0;text-align:right;"><b>{point.y:.2f}%</b>  (' + dateFormat + ')</td></tr>',

                    footerFormat: '</table>',
                    useHTML: true,
                };
            } else {
                return {
                    shared: true,
                    split: false,
                    headerFormat: '<table>',
                    pointFormat: '<tr><td style="color:{series.color}; padding: 0">{series.name}: </td>' + '<td style="padding:0;text-align:right;"><b>{point.y:,.2f}</b>  (' + dateFormat + ')</td></tr>',
                    footerFormat: '</table>',
                    useHTML: true,
                };
            }
        },
    },

    methods: {
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },

        clearChart() {
            while (this.$refs.chartComponent.chart.series.length > 0) {
                this.$refs.chartComponent.chart.series[0].remove(true);
            }
            this.$refs.chartComponent.chart.annotations.forEach((annotation) => annotation.destroy());
            this.$refs.chartComponent.chart.annotations.length = 0;

            while (this.$refs.chartComponent.chart.yAxis.length > 0) {
                this.$refs.chartComponent.chart.yAxis[0].remove(true);
            }
        },

        isDollarBased(metric) {
            return metric.valueType == 'Millions' || metric.valueType == '$ & cents';
        },

        isFiscalMetric(selectedMetric) {
            return selectedMetric.periodTypes[0] == ANNUAL || selectedMetric.periodTypes[0] == QUARTERLY;
        },

        fetchFiscalData() {
            let lookupKey = this.selectedMetric.measureId + '.' + this.selectedPeriodType.value;
            let calls = [];
            this.securities.forEach((sec) => {
                calls.push(this.getFiscalMetricData(lookupKey, sec));
            });
            return calls;
        },

        async getFiscalMetricData(lookupKey, sec) {
            if (!sec.metricData.has(lookupKey)) {
                
                return new Promise((resolve, reject) => {  //eslint-disable-line
                   
                    DataService.getMetricData(this.selectedPeriodType.value, this.selectedMetric.measureId, sec.securityId).then((resp) => {
                        let ref = new Date();
                        if (resp.data.status == 'success') {
                            let dataPoints = this.createFiscalDataPointsArray();
                            resp.data.data.forEach((measureValue) => {
                                dataPoints[this.getCategoryIndex(measureValue.rank)].y = this.getAdjustedMeasureValue(measureValue.measureValue);
                                let d = parse(measureValue.fiscalEndDate, 'MMM d, yyyy h:mm:ss a', ref);
                                dataPoints[this.getCategoryIndex(measureValue.rank)].fiscalEndDate = d.getTime();
                            });

                            sec.metricData.set(lookupKey, dataPoints);
                        } else {
                            sec.metricData.set(lookupKey, []);
                        }
                        
                        resolve(sec);
                    });
                });
            } else {
                return Promise.resolve(sec);
            }
        },

        getAdjustedMeasureValue(measureValue) {
            if ( this.selectedMetric.valueType == 'Decimal Percentage' || this.selectedMetric.valueType == 'Percent') {
                if (measureValue) {
                    return measureValue * 100;
                } else {
                    return measureValue;
                }
            } else {
                return measureValue;
            }
        },

        createFiscalDataPointsArray() {
            let cats = this.getCategories();
            let dataPoints = [];
            cats.forEach((cat) => {
                dataPoints.push({ fy: cat, y: null, fiscalEndDate: null });
            });
            return dataPoints;
        },

        getCategory(rank) {
            if (this.selectedPeriodType.value == ANNUAL) {
                // rank = 2   annual Categories lenth is 6 we want this position [ _, _, _, 2, _, _]
                let index = this.annualCategories.length - rank - 1;
                return this.annualCategories[index];
            } else {
                let index = this.quarterlyCategories.length - rank - 1;
                return this.quarterlyCategories[index];
            }
        },

        getCategoryIndex(rank) {
            if (this.selectedPeriodType.value == ANNUAL) {
                // rank = 2   annual Categories lenth is 6 we want this position [ _, _, _, 2, _, _]
                return this.annualCategories.length - rank - 1;
            } else {
                return this.quarterlyCategories.length - rank - 1;
            }
        },

        getCategories() {
            if (this.selectedPeriodType.value == ANNUAL) {
                return this.annualCategories;
            } else if (this.selectedPeriodType.value == QUARTERLY) {
                return this.quarterlyCategories;
            } else {
                return [];
            }
        },

        fetchDailyData() {
            let lookupKey = this.selectedMetric.measureId + '.' + DAILY;
            let calls = [];
            this.securities.forEach(async (sec) => {
                calls.push(this.getDailyMetricData(lookupKey, sec));
            });
            return calls;
        },

        async getDailyMetricData(lookupKey, sec) {
            if (!sec.metricData.has(lookupKey)) {
               
                return new Promise((resolve, reject) => { //eslint-disable-line
                    
                    DataService.getMetricData(DAILY, this.selectedMetric.measureId, sec.securityId).then((resp) => {
                        if (resp.data.status == 'success') {
                            let dataPoints = [];
                            let ref = new Date();
                            resp.data.data.forEach((measureValue) => {
                                let d = parse(measureValue.measureDate, 'MMM d, yyyy h:mm:ss a', ref);

                                dataPoints.push([d.getTime(), this.getAdjustedMeasureValue(measureValue.measureValue)]);
                            });

                            sec.metricData.set(lookupKey, dataPoints);
                        } else {
                            sec.metricData.set(lookupKey, []);
                        }
                        this.$refs.chartComponent.chart.hideLoading();
                        resolve(sec);
                        
                    }) ;
                });
            } else {
                return Promise.resolve(sec);
            }
        },

        buildChart() {
            if( this.$refs.chartComponent.chart ){
                this.$refs.chartComponent.chart.showLoading('Loading data...');
                this.updateYAxis();
                if (this.isFiscalMetric(this.selectedMetric)) {
                    Promise.allSettled(this.fetchFiscalData()).then(() => {
                        this.buildFiscalChart();
                        this.$refs.chartComponent.chart.hideLoading();
                    });
                } else {
                    Promise.allSettled(this.fetchDailyData()).then(() => {
                        this.buildDailyChart();
                        this.$refs.chartComponent.chart.hideLoading();
                        
                    });
                }
            }
        },

        buildFiscalChart() {
            while (this.$refs.chartComponent.chart.series.length) {
                this.$refs.chartComponent.chart.series[0].remove(true);
            }

            this.$refs.chartComponent.chart.update({ tooltip: this.tooltip });
            this.$refs.chartComponent.chart.xAxis[0].update({ type: 'category', categories: this.getCategories() }, false);
            //this.$refs.chartComponent.chart.addAxis({type: 'category', categories: this.getCategories()}, true)

            let lookupKey = this.selectedMetric.measureId + '.' + this.selectedPeriodType.value;
            let colors = ['#33CC99', '#E63E3E', '#693BF5', '#FFBD53', '#5367FF', '#DA8C3C', '#584684', '#7CADC4', '#3DA85E', '#F4BF6A', '#DA3FBC'];
            var i = 0;

            this.securities.forEach((sec) => {
                let series = {
                    type: 'column',
                    id: '' + sec.securityId,
                    name: sec.symbol,
                    color: colors[i],
                    data: sec.metricData.get(lookupKey),
                };

                this.$refs.chartComponent.chart.addSeries(series);
                ++i;
            });
            this.$refs.chartComponent.chart.redraw();
            this.$refs.chartComponent.chart.reflow();
        },

        fiveYearsAgoMillis() {
            let now = new Date();
            return add(now, { years: -5 }).getTime();
        },

        buildDailyChart() {
            while (this.$refs.chartComponent.chart.series.length) {
                this.$refs.chartComponent.chart.series[0].remove(true);
            }
            this.$refs.chartComponent.chart.update({ tooltip: this.tooltip });
            this.$refs.chartComponent.chart.xAxis[0].update(
                {
                    type: 'datetime',
                    labels: {
                        format: '{value:%Y-%m-%d}',
                    },
                    // min: (() => {this.fiveYearsAgoMillis()})(),
                    //max: (new Date()).getTime()}
                },
                true
            );
            // this.$refs.chartComponent.chart.addAxis(
            //      {type: 'datetime',
            //         labels: {
            //             format: "{value:%Y-%m-%d}"
            //         },
            //         min: (() => {this.fiveYearsAgoMillis()})(),
            //        max: (new Date()).getTime()}, true)
            let lookupKey = this.selectedMetric.measureId + '.' + DAILY;
            let colors = ['#33CC99', '#E63E3E', '#693BF5', '#FFBD53', '#5367FF', '#DA8C3C', '#584684', '#7CADC4', '#3DA85E', '#F4BF6A', '#DA3FBC'];

            var i = 0;

            this.securities.forEach((sec) => {
                let series = {
                    type: 'line',
                    id: '' + sec.securityId,
                    name: sec.symbol,
                    color: colors[i],
                    yAxis: 0,
                    data: sec.metricData.get(lookupKey),
                };
                this.$refs.chartComponent.chart.addSeries(series);
                ++i;
            });

            this.$refs.chartComponent.chart.redraw();
            this.$refs.chartComponent.chart.reflow();
        },

        updatePeriodType(periodType, buildChart = true) {
            this.selectedPeriodType = periodType;
            if (this.selectedMetric && this.securities.length > 0) {
                while (this.$refs.chartComponent.chart.series.length) {
                    this.$refs.chartComponent.chart.series[0].remove(true);
                }
                if (buildChart) this.buildChart();
            }
        },

        updateSecurities(toGraph, buildChart = true) {
            this.securities = toGraph;
            this.securities.forEach((sec) => {
                if (!sec['metricData']) {
                    sec['metricData'] = new Map();
                }
            });

            if (this.selectedMetric && buildChart) {
                this.buildChart();
            }
        },

        updateMetric(selectedMetric, buildChart = true) {
            this.selectedMetric = selectedMetric;

            if (this.securities.length > 0 && buildChart) {
                this.buildChart();
            }
        },

        updateYAxis() {
            while (this.$refs.chartComponent.chart.yAxis.length) {
                this.$refs.chartComponent.chart.yAxis[0].remove(true);
            }

            if (this.isDollarBased(this.selectedMetric)) {
                this.$refs.chartComponent.chart.addAxis({
                    labels: {
                        align: 'left',
                        formatter: function () {
                            return '$' + numeral(this.value).format('0a');
                        },
                    },
                    id: 'dollar-based-axis',
                    lineWidth: 1,
                    gridLineDashStyle: 'dot',
                });
            } else if (this.selectedMetric.valueType == 'Ratio') {
                this.$refs.chartComponent.chart.addAxis({
                    labels: {
                        align: 'left',
                        formatter: function () {
                            return numeral(this.value).format('0.00') + 'x';
                        } 
                    },
                    lineWidth: 0,
                    id: 'ratio-based-axis',

                    gridLineDashStyle: 'dot',
                });
            } else if (this.selectedMetric.valueType == 'Percent') {
                this.$refs.chartComponent.chart.addAxis({
                    labels: {
                        align: 'left',
                        formatter: function () {
                            return numeral(this.value).divide(100).format('0.00%');
                        } 
                    },
                    lineWidth: 0,
                    id: 'percent-based-axis',

                    gridLineDashStyle: 'dot',
                });
            } else {
                this.$refs.chartComponent.chart.addAxis({
                    labels: {
                        align: 'left',
                        format: '{value:.2f}',
                    },
                    lineWidth: 0,
                    id: 'actual-based-axis',

                    gridLineDashStyle: 'dot',
                });
            }
        },

        openSnapshotDialog() {
            console.log("opening snapshot dialog")
            this.snapshotTarget = this.$refs.chartComponent.$el.getElementsByClassName('highcharts-container')[0];
            this.displaySnapshot = true;
        },
    },
};
</script>

<style scoped>
.comparisonChart {
    max-height: 400px;
    min-height: 35vh;
    float: none !important;
    height: auto;
}

::v-deep(.highcharts-menu-wrapper) {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

::v-deep(.highcharts-toggle-toolbar) {
    display: none;
}
/* Begin GUI tools */

/* ::v-deep(.highcharts-annotation-shapes > circle){
    fill: rgba(166, 166, 166, 0.3);
    stroke: rgba(166, 166, 166, 0.3);
} */

/* ::v-deep(.highcharts-annotation-shapes > path){
     fill: rgba(166, 166, 166, 0.3);
    stroke: rgba(166, 166, 166, 0.3);
    stroke-width: 3;
} */

/* end GUI tools */

/* the range selector buttons*/

::v-deep(.invrs-chart-button-pressed > rect) {
    background-color: #33cc99;
    fill: #33cc99;
}

::v-deep(.invrs-chart-button-pressed > text) {
    color: #ffffff !important;
    fill: #ffffff !important;
    font-weight: normal !important;
}

::v-deep([class*='highcharts']) {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.highcharts-range-label) {
    color: #33cc99;
}

/** hide the highcharts logo */
::v-deep(.highcharts-credits) {
    display: none;
}

/** highcharts popup styling */
::v-deep(.highcharts-popup button) {
    padding: 0.75rem 1rem;
}

:v-deep(.highcharts-popup button:hover) {
    background: #e9ecef;
}

::v-deep(.highcharts-popup-bottom-row) {
    /* //float: left;
    /* padding: 0px 20px; */
    /* width: calc(100% - 40px);  */
    padding: 0.5rem;
}

::v-deep(.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li) {
    border-radius: 4px !important;
}
::v-deep(.highcharts-menu-item-btn) {
    border-radius: 4px !important;
}
::v-deep(.highcharts-axis-labels.highcharts-yaxis-labels text) {
    color: #32364e !important;
    fill: #32364e !important;
    font-size: 12px !important;
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels text) {
    color: #999 !important;
    fill: #999 !important;
    font-size: 14px !important;
}
::v-deep(.highcharts-axis.highcharts-yaxis .highcharts-axis-line) {
    stroke: none;
}

::v-deep(.highcharts-legend .highcharts-legend-item text) {
    color: #999 !important;
    fill: #999 !important;
    font-size: 14px !important;
    font-weight: normal !important;
}
::v-deep(.highcharts-legend .highcharts-legend-item:hover text) {
    color: #32364e !important;
    fill: #32364e !important;
}
</style>